<template>
<div class="scene-container">
    <canvas @click.prevent ref="bjscanvas" class="canvas"/>
</div>
</template>

<script>
import {createScene} from "@/scenes/FS-Ecliptica"
export default {
    name: "BabylonScene",
    mounted () {
        const bjscanvas = this.$refs.bjscanvas
        if (bjscanvas) {
            createScene(bjscanvas)
        }
    }
}
</script>

<style>
canvas {
    width: 100%;
    height: 800px;
    border: 5px solid var(--color-main);
    border-radius: 8px;
    outline: none;
}


</style>