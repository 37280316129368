import { Texture, StandardMaterial, Color3, FresnelParameters, Color4 } from "@babylonjs/core"

function redDwarfMaterial (scene) {
    const texture = new Texture("textures/reddwarftexture.jpg",scene)
    const material = new StandardMaterial("reddwarfmaterial", scene)
    material.emissiveColor = Color3.Red()
    material.specularColor = Color3.Black()
    material.diffuseTexture = texture
    return material
}

function neutronStarMaterial (scene) {
    const texture = new Texture("textures/neutronstartexture.jpg",scene)
    const material = new StandardMaterial("neutronstarmaterial", scene)
    material.emissiveColor = new Color3(0.329, 0.62, 0.29)
    material.diffuseColor = Color3.Blue()
    material.diffuseTexture = texture
    material.specularColor = Color3.Black()
    return material
}

function classGStarMaterial(scene) {
    const texture = new Texture("textures/classgtexture.jpg",scene)
    const material = new StandardMaterial("classgmaterial", scene)
    material.emissiveColor = new Color4(1, 0.949, 0.769)
    
    material.specularColor = Color3.Black()
    material.diffuseTexture = texture
    return material
}

function blackHoleMaterial (scene) {
    const texture = new Texture("textures/blackhole.jpg",scene)
    const material = new StandardMaterial("blackhole", scene)
    material.reflectionTexture = texture
    material.reflectionTexture.coordinatesMode = Texture.PLANAR_MODE
    material.indexOfRefraction = 0
    material.diffuseColor = Color3.Black()
    return material
}

export {redDwarfMaterial, neutronStarMaterial, classGStarMaterial, blackHoleMaterial}