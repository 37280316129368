<template>
<img src="@/assets/starnav.webp" style="width: 30%; margin-top: 20px"/>
<h3>Ricalcolo...</h3>
<div class="outer-container">
  <div class="text-container" v-show="descriptionopen">
    <p>Per un GDR a tema fantascientifico, qualche tempo fa ho costruito un piccolo sistema di stelle e pianeti che sarebbe poi diventato l'universo di gioco.</p>
    <p>Ho quindi pensato che sarebbe stato carino progettare e costruire questo piccolo settore spaziale in 3D, che trovate qui a fianco. </p>
    <p>Ho utilizzato le librerie e il motore grafico di <a href="https://www.babylonjs.com/">Babylon.js</a>, sia per il rendering della scena sia per l'interfaccia. In alto a destra trovate la lista delle stelle disponibli
      , che potete cercare inserendo il nome in alto a sinistra.
    </p>
    <p>In questo universo, per spostarsi da un sistema stellare all'altro è necessario "saltare" da una stella all'altra. Dal pannello di navigazione in basso e possibile tracciare
      il percorso da un sistema all'altro. Per il calcolo del percorso, ho utilizzato una semplice implementazione dell'algortimo di <a href="https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm">Dijkstra</a>.
    </p>
    <p>Buona navigazione! 🚀</p>
    <br>
    <p class="closedescription" @click="descriptionopen = false"> Chiudi descrizione </p>
  </div>
  
  <babylon-scene class="babylonscene"></babylon-scene>  
</div>
  
</template>

<script>

import BabylonScene from '@/components/babylon/BabylonScene.vue'
import App from '@/App.vue'


export default {
  components : {BabylonScene, App},
  data () {
    return {
      descriptionopen: true
    }
  }
}
</script>

<style scoped>
 .outer-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-content: space-around;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
 }
 .text-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 20%;
 }
 .closedescription {
  color: var(--color-button);
  cursor: pointer;
  font-weight: bold;
  text-decoration: var(--color-button) underline 

 }
</style>