<template>
  <div class="projects-outer" v-if="!finished">
    <img src="@/assets/icons/hourglass.png" alt=" clessidra">
    <h1>Pagina in fase di modifica.</h1>
    <h3>Stay tuna!</h3>
    <iframe src="https://giphy.com/embed/26FxP5WOBDNUI0hKo" width="480" height="269" style="border-radius: 10px;" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/greenpeace-tuna-26FxP5WOBDNUI0hKo">via GIPHY</a></p>
  </div>
  <div class="projects-outer" v-if="finished">
    <p>
      Ecco alcuni dei progetti su cui ho lavorato <br />
      (alcuni sono in fase di costruzione, possono cambiare spesso)
    </p>
    <div class="cards-container">
      <ProjectCardVue class="project-card" :title="c.name" :description="c.caption" :img="c.img" :links="c.link" v-for="c in collectedData" :key="c.id"></ProjectCardVue>
    </div>
  </div>
</template>

<script>
import ProjectCardVue from '@/components/ProjectCard.vue';

export default {
  components : {ProjectCardVue},
  data() {
    return {
      collectedData: [],
      finished : true
    };
  },
  mounted() {
    var metadata = require.context("@/assets/", false, /\.json$/i);
    metadata
      .keys()
      .map(metadata)[0]
      .forEach((element) => {
        this.collectedData.push(element);
      });
    console.log(this.collectedData)
  },
};
</script>

<style scoped>
p {
  text-align: center;
}

.projects-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-unit);
  margin-top: var(--space-unit);
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: var(--space-unit);
  width: 90%;
  gap: var(--space-uuuunit);
  padding-top: 2%;
  padding-bottom: 2%;
}

.project-card:hover {
  padding: 50px;
  border: 3px solid var(--color-project-card-brd);
  box-shadow: 4px 4px 8px var(--color-main-shadow);
}

</style>