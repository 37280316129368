import { ActionManager, Color4, CreatePlane, Mesh, Observable, Scene } from "@babylonjs/core";
import { AdvancedDynamicTexture, Button, Control, InputText, InputTextArea, Rectangle, ScrollViewer, TextBlock, TextWrapping } from "@babylonjs/gui";
import stars from "@/scenes/data/stars.json"

// Builds and sets the search bar
function buildSearchInput (name, placeholder) {
    var out = new InputText(name)
    out.width = "200px"
    out.height = "24px"
    out.placeholderText = placeholder
    out.placeholderColor = "white"
    out.background = "black"
    out.color = "white"
    out.top = "-47%"
    out.left = "-42.5%"
    out.fontSize = "12px"
    return out
}

// Builds and sets the search button trigger. Need input text and scene to perform the meshes search.
function buildSearchButton (inputtext, scene) {
    var out = Button.CreateSimpleButton("starselection","Vai")
    out.left = "-33.5%"
    out.top = "-47%"
    out.fontSize = "12px"
    out.color = "white"
    out.width = "50px"
    out.height = "24px"
    out.background = "black"
    out.onPointerClickObservable.add(function () {
        if (inputtext.text == "") {
            return
        }
        var lowercaseinput = inputtext.text.toLowerCase()
        scene.meshes.forEach (it => {
            if (it.name.toLowerCase() == lowercaseinput) {
                scene.getMeshByName(it.name).actionManager.processTrigger(ActionManager.OnPickTrigger)
            }
        })
    })
    return out
}

// Builds and sets the list of stars as control
function buildStarList (stars) {

    // Creates a scrollview bar
    var scrollbar = new ScrollViewer("scrollbar")
    scrollbar.thickness = 1
    scrollbar.color = "white"
    scrollbar.barSize = 12
    scrollbar.barColor = "gray"
    scrollbar.width = "100%"
    scrollbar.height = "100%"
    scrollbar.background = "transparent"

    // The actual textblock
    var textblock = new TextBlock("textblock")
    textblock.textWrapping = TextWrapping.WordWrap
    textblock.resizeToFit = true
    textblock.color = "white"
    textblock.fontSize = "13"
    textblock.outlineColor = "white"
    textblock.paddingRight = "10px"
    textblock.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP
    textblock.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT
    textblock.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP
    textblock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT
    textblock.isVisible = true
    var textToDisplay = ""
    stars.forEach (it => {
        textToDisplay += "\n"+it.name
    })
    textblock.text = textToDisplay
    textblock.isReadOnly = true
    scrollbar.addControl(textblock)
    
    // The background of the textbox
    var rectangle = new Rectangle("rectangle")
    rectangle.left = "43.2%"
    rectangle.top = "-30%"
    rectangle.width = "180px"
    rectangle.height = "300px"
    rectangle.thickness = 0
    rectangle.background = "black"
    rectangle.addControl(scrollbar)
    return rectangle
}

function navigationBlock () {
    var out = new Rectangle("navigation_background")
    out.background = "black"
    out.width = "300px"
    out.height = "300px"
    out.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP
    out.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT
    out.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT
    out.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP
    out.left = "79%"
    out.top = "62%"

    var textinfo = new TextBlock("navtitle", "Navigazione")
    textinfo.left = "-31%"
    textinfo.top = "-46%"
    textinfo.isReadOnly = true
    textinfo.color = "white"
    textinfo.fontSize = "16px"
    textinfo.fontWeight = "bold"
    out.addControl(textinfo)

    var startingPoint = new InputText("startingpoint")
    startingPoint.color = "white"
    startingPoint.placeholderColor = "white"
    startingPoint.placeholderText = "Stella di partenza..."
    startingPoint.background = "transparent"
    startingPoint.width = "280px"
    startingPoint.height = "24px"
    startingPoint.top = "-35%"
    startingPoint.left = "-1%"
    startingPoint.fontSize = "12px"
    out.addControl(startingPoint)

    var endPoint = new InputText("endpoint")
    endPoint.color = "white"
    endPoint.placeholderColor = "white"
    endPoint.placeholderText = "Stella di arrivo..."
    endPoint.background = "transparent"
    endPoint.width = "280px"
    endPoint.height = "24px"
    endPoint.top = "-25%"
    endPoint.left = "-1%"
    endPoint.fontSize = "12px"
    out.addControl(endPoint)

    var navigateButton = Button.CreateSimpleButton("navigatebutton", "Calcola percorso")
    navigateButton.background = "transparent"
    navigateButton.color = "white"
    navigateButton.width = "150px"
    navigateButton.height = "24px"
    navigateButton.fontSize = "12px"
    navigateButton.top = "-13%"
    navigateButton.onPointerClickObservable.add(async function () {
        
    })
    out.addControl(navigateButton)

    var resultingNodesPath = new TextBlock("resultingNodesPath")
    resultingNodesPath.fontSize = "10px"
    resultingNodesPath.color = "white"

    var resultsScroller = new ScrollViewer("resultsscroller")
    resultsScroller.left = "-1%"
    resultsScroller.top = "20%"
    resultsScroller.width = "280px"
    resultsScroller.height = "150px"
    resultsScroller.background = "transparent"
    resultsScroller.addControl(resultingNodesPath)

    out.addControl(resultsScroller)

    return out
}


// Builds the GUI and returns it to the scene
function BuildGUI (scene) {
    var gui = AdvancedDynamicTexture.CreateFullscreenUI("fullui")
    var starInput = buildSearchInput("searchstarinput", "Nome stella...")
    var button = buildSearchButton(starInput,scene)
    var starslist = buildStarList(stars)
    var navigator = navigationBlock()

    gui.addControl(starInput)
    gui.addControl(button)
    gui.addControl(starslist)
    gui.addControl(navigator)
    return gui
}

export {BuildGUI}