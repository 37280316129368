import { Engine, Scene, Vector3, MeshBuilder, HemisphericLight, UniversalCamera, CubeTexture, GlowLayer, ActionManager, InterpolateValueAction, ExecuteCodeAction} from "@babylonjs/core";
import { blackHoleMaterial, classGStarMaterial, neutronStarMaterial, redDwarfMaterial } from "./materialsbuilder";
import { generateStar } from "./scenefuncs";
import { AdvancedDynamicTexture, TextBlock } from "@babylonjs/gui";
import { BuildGUI } from "./guibuilder";

const createScene = (canvas) => {
    const engine = new Engine(canvas)
    const scene = new Scene(engine)
    const stars = require("@/scenes/data/stars.json")
    const actionManager = new ActionManager(scene)

    //SKYBOX
    const envTexture = new CubeTexture("/textures/skybox/",scene)
    scene.createDefaultSkybox(envTexture,true,5000)



    //LIGHT
    var light = new HemisphericLight("light", new Vector3(0,1,0),scene)
    light.intensity = 0.5

    //GLOWER
    var glower = new GlowLayer("glower", scene)
    glower.intensity = .435
    
    //GUI
    var gui = BuildGUI(scene)


    //CAMERA
    const camera = new UniversalCamera("camera1", new Vector3(500,500,500), scene)
    camera.setTarget(Vector3.Zero())
    camera.attachControl(canvas,true)

    stars.forEach(element => {
        var star = undefined
        if (element.Subtype=="Stella di neutroni") {
            star = generateStar(scene,camera,element,neutronStarMaterial(scene))
            var disc = MeshBuilder.CreateDisc("disc"+element.Name, {radius: 1,arc: .5},scene)
            disc.material = neutronStarMaterial(scene)
        } else if (element.Subtype=="Gialla (Classe G)") {
            star = generateStar(scene,camera,element,classGStarMaterial(scene))
        } else if (element.Subtype=="Buco Nero") {
            star = generateStar(scene,camera,element,blackHoleMaterial(scene))
        } 
        else {
            star = generateStar(scene,camera,element,redDwarfMaterial(scene),actionManager)
        }

        var plane = MeshBuilder.CreatePlane(element.name+"_plane",scene)
        
        
        var advancedTexture = AdvancedDynamicTexture.CreateForMesh(plane,300,300)
        advancedTexture.attach()
        advancedTexture.attachToMesh(star)
        
        var text = `Nome:${element.name}
Sottotipo: ${element.Subtype}
Coordinate: ${element.coordx},${element.coordy},${element.coordz}`

        var label = new TextBlock(element.name+"_info",text)
        label.color = "white"
        label.fontSize = "20"
        label.textHorizontalAlignment = 0
        advancedTexture.addControl(label)
        plane.visibility = 0
        star.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger,() => {
                scene.meshes.filter(it => it.name.includes("_plane")).forEach(that => that.visibility = 0)
            })
        )
        star.actionManager.registerAction(
            new InterpolateValueAction(ActionManager.OnPickTrigger,plane,"visibility",1)
        )
        star.actionManager.registerAction (
            new InterpolateValueAction(ActionManager.OnPickTrigger, plane, "position.x",camera.position.x)
        )
        star.actionManager.registerAction (
            new InterpolateValueAction(ActionManager.OnPickTrigger, plane, "position.y", camera.position.y)
        )

        star.actionManager.registerAction (
            new InterpolateValueAction(ActionManager.OnPickTrigger,plane,"position.z", camera.position.z + 2)
        )
        
    });

    light.excludedMeshes = scene.meshes
    engine.runRenderLoop(() => {
        scene.render()
    })
}

export {createScene}