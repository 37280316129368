<template>
  <p>Puoi trovarmi su:</p>
  <div class="social-container">
    <a class="img-link" href="https://www.instagram.com/moro.nicola/"
      ><img
        class="icon-image"
        src="@/assets/icons/instaicon.svg"
        alt="instagram-icon"
    /></a>
    <a
      class="img-link"
      href="https://www.linkedin.com/in/nicola-moro-01530111a/"
      ><img
        class="icon-image"
        src="@/assets/icons/linkedinicon.svg"
        alt="linkedin-icon"
    /></a>
    <a class="img-link" href="https://www.reddit.com/user/NicolaM1994/"
      ><img
        class="icon-image"
        src="@/assets/icons/redditicon.svg"
        alt="reddit-icon"
    /></a>
    <!--
    <a class="img-link" href="https://www.facebook.com/nicola.moro94/"
      ><img
        class="icon-image"
        src="@/assets/icons/facebookicon.svg"
        alt="facebook-icon"
    /></a>-->
    <a class="img-link" href="https://github.com/NicolaM94"
      ><img class="icon-image" src="@/assets/icons/githubicon.svg" alt=""
    /></a>
  </div>
</template>


<style>
.img-link {
  display: contents;
}
.social-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
</style>