import {CreateSphere, Vector3, ActionManager, InterpolateValueAction, HemisphericLight} from "@babylonjs/core"

function generateStar(scene, camera, stardata, material) {
    var mesh = CreateSphere(stardata.name, {diameter: Math.random()*1.5, segments: 16}, scene)
    mesh.position = new Vector3(stardata.coordx, stardata.coordy, stardata.coordz)
    mesh.actionManager = new ActionManager
    
    mesh.actionManager.registerAction(new InterpolateValueAction(ActionManager.OnPickTrigger,camera,"position.x",mesh.position.x+2.5))
    mesh.actionManager.registerAction(new InterpolateValueAction(ActionManager.OnPickTrigger,camera,"position.y",mesh.position.y))
    mesh.actionManager.registerAction(new InterpolateValueAction(ActionManager.OnPickTrigger,camera,"position.z",mesh.position.z-5))
    mesh.actionManager.registerAction(new InterpolateValueAction(ActionManager.OnPickTrigger,camera,"target",new Vector3(mesh.position.x+1.75, mesh.position.y, mesh.position.z)))
    
    var starlight = new HemisphericLight("starlight", mesh.Vector3, scene)
    starlight.intensity = 1
    mesh.material = material
    //mesh.lightSources = [starlight]
    mesh.rotation.y = Math.PI/8
    mesh.rotation.x = -Math.PI/8
    return mesh
}

export {generateStar}