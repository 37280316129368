<template>
  <div class="project-card-outer">
    <div class="project-title-container">
        <img class="project-image-logo" :src=this.img alt="logo">
        <h2 class="project-title">{{this.title}}</h2>
    </div>
    <p class="project-description">{{this.description}}</p>
    <p>Dove trovarlo:</p>
    <div class="project-link-container">
        <a :href=l.url v-for="l in links" :key="l">{{l.name}}</a>
    </div>
  </div>
</template>

<script>
export default {
    props : ['title',"description","links","img"]

}
</script>

<style>
.project-card-outer {
    background-color: var(--color-project-card-bg);
    padding: 20px;
    border: 1px solid var(--color-project-card-brd);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    box-shadow: 2px 2px 4px gray;
}


.project-title {
    color: var(--color-main);
}
.project-link-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-self: flex-start;
}
.project-title-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 20px;
    align-self: center;
    align-items: center;
}
.project-image-logo {
    max-height: 50px;
    max-width: 50px;
}
</style>